import {buildApiRoot} from "./helper";

const env = process.env.REACT_APP_ENV ?? "dev";

interface URLs {
  fmsCustomerPathUrl: string;
}

const configs: Record<string, URLs> = {
  dev: {
    fmsCustomerPathUrl: "https://devapi.apply.nutrienagsolutions.com.au/customer"
  },
  staging: {
    fmsCustomerPathUrl: "https://stageapi.apply.nutrienagsolutions.com.au/customer"
  },
  uat: {
    fmsCustomerPathUrl: "https://uatapi.apply.nutrienagsolutions.com.au/customer"
  },
  pp: {
    fmsCustomerPathUrl: "https://ppapi.apply.nutrienagsolutions.com.au/customer"
  },
  train: {
    fmsCustomerPathUrl: "https://trainapi.apply.nutrienagsolutions.com.au/customer"
  },
  prod: {
    fmsCustomerPathUrl: "https://api.apply.nutrienagsolutions.com.au/customer"
  },

};

const apiConfig = configs[env] ?? configs.dev;

apiConfig.fmsCustomerPathUrl = buildApiRoot(window.location.hostname, apiConfig.fmsCustomerPathUrl);

export { apiConfig };

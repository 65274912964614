const env = process.env.REACT_APP_ENV ?? "dev";

interface IConfig {
  configCatKey: string;
}

const config: { [key: string]: IConfig } = {
  dev: {
    configCatKey: "D-HZCJIlhk6Kmg5MNYlV8w/H7_v3N0_5keUEnj0n3h6Sw"
  },
  staging: {
    configCatKey: "D-HZCJIlhk6Kmg5MNYlV8w/oWvCzRhQ602N6KakwT_UFQ"
  },
  uat: {
    configCatKey: "D-HZCJIlhk6Kmg5MNYlV8w/kjRnsgUHukGdWAPeO1AW3g"
  },
  prod: {
    configCatKey: "D-HZCJIlhk6Kmg5MNYlV8w/yi26bUW-KEqrwmMEusC6Ww"
  },
  pp: {
    configCatKey: "D-HZCJIlhk6Kmg5MNYlV8w/0fxpXlUlUE6YJCjwthtk6Q"
  },
  train: {
    configCatKey: "D-HZCJIlhk6Kmg5MNYlV8w/k_w6luixrUWEzzZJGFK5jQ"
  }
};

export default config[env] ?? config.dev;

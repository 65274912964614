export function buildApiRoot(hostname: string, defaultApiRoot: string) {
    if (hostname.includes("local")) {
        return defaultApiRoot;
    }
    const hostParts = hostname.split(".");
    const path = defaultApiRoot.split('/').reverse()[0];
    if (hostParts.length === 4) {
        // prod
        return `https://api.${hostParts[0]}.${hostParts[1]}.com.au/${path}`;
    } else if (hostParts.length === 5) {
        // non-prod
        return `https://${hostParts[0]}api.${hostParts[1]}.${hostParts[2]}.com.au/${path}`
    } else {
        // localhost
        return defaultApiRoot;
    }
}